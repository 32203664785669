import {Link} from 'react-router-dom';
import { Home } from './Pages/Home';
import { Price } from './Pages/Price';
import { Resources } from './Pages/Resources';
import { Solutions } from './Pages/Solutions';
import { Product } from './Pages/Product';
import {createBrowserRouter,RouterProvider,BrowserRouter,Routes,Route} from 'react-router-dom';


function App() {
  return <BrowserRouter>
  <Routes>
    <Route path='/' element={<Home></Home>}></Route>
    <Route path='/product' element={<Product></Product>}></Route>
    <Route path='/resources' element={<Resources></Resources>}></Route>
    <Route path='/solutions' element={<Solutions></Solutions>}></Route>
    <Route path='/pricing' element={<Price></Price>}></Route>
  </Routes>
  </BrowserRouter>
}

export default App;
