import React from 'react'
import { NavBar } from '../Components/navBar'

export const Price = () => {
    return <div className="w-[100%] h-[100%] bg-gradient-to-br from-[#93EDFD] to-[#7DB1FB flex flex-col items-center">
                <NavBar></NavBar>
                <div className="h-[80%] w-[100%] flex justify-center">
                    <p className='flex text-center'>
                    Scale your Business processes using Orthogonaldb, an open-source, production ready Vector Database <br></br> 
                    to build AI applications. Choose from the array of Payment Plans for your needs.
                    </p>
                    <div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    
                </div>

            </div>
}
