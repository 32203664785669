import React from 'react'
import {Link} from 'react-router-dom'
import { FaDatabase } from "react-icons/fa";
import { GiSpanner } from "react-icons/gi";
import { GrResources } from "react-icons/gr";
import { MdOutlinePriceChange } from "react-icons/md";
import { FaGithubSquare } from "react-icons/fa";
import { FaCloud } from "react-icons/fa";
import { useState } from 'react';


export const NavBar = () => {
    const [prodNav,setProdNav] = useState(false)
    const [solNav,setSolNav] = useState(false)
    const [resoNav,setResoNav] = useState(false)
    const [priceNav,setPriceNav] = useState(false)

  return <div className="flex w-[100%] h-[8%] justify-between text-black">
  <div className="flex items-center">
    <img className="h-10 w-10 rounded" src="dblogo.png">
    </img>
    <div>Custosys</div>
  </div>
  <div className='flex justify-between'>
    <Link to={`/product`} className='flex items-center justify-between m-5 relative w-100' onMouseOver={(e)=>setProdNav(true)}>
        Product
        <FaDatabase></FaDatabase>
        <div className={prodNav?'absolute inset-0 w-50 top-6':'hidden'} onMouseOut={(e)=>setProdNav(false)}>
            <ul className='bg-white w-60 text-center '>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >Overview Summary</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >System Integration</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >Scale</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`}>Container security</Link>
                </li>
            </ul>
        </div>
    </Link>
    <Link to={`solutions`} className='flex items-center justify-between m-5 relative w-100' onMouseOver={(e)=>setSolNav(true)}>
        Solutions
        <GiSpanner></GiSpanner>
        <div className={solNav?'absolute inset-0 w-50 top-6':'hidden'} onMouseOut={(e)=>setSolNav(false)}>
            <ul className='bg-white w-60 text-center '>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >Overview Summary</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >System Integration</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >Scale</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`}>Embeddings</Link>
                </li>
            </ul>
        </div>
    </Link>
    <Link to={`resources`} className='flex items-center justify-between m-5 relative w-100' onMouseOver={(e)=>setResoNav(true)}>
        Resources
        <GrResources></GrResources>
        <div className={resoNav?'absolute inset-0 w-50 top-6':'hidden'} onMouseOut={(e)=>setResoNav(false)}>
            <ul className='bg-white w-60 text-center '>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >Overview Summary</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >System Integration</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >Scale</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`}>Embeddings</Link>
                </li>
            </ul>
        </div>
    </Link>
    <Link to={`pricing`} className='flex items-center justify-between m-5 relative w-100' onMouseOver={(e)=>setPriceNav(true)}>
        Pricing
        <MdOutlinePriceChange></MdOutlinePriceChange>
        <div className={priceNav?'absolute inset-0 w-50 top-6':'hidden'} onMouseOut={(e)=>setPriceNav(false)}>
            <ul className='bg-white w-60 text-center '>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >Overview Summary</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >System Integration</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`} >Scale</Link>
                </li>
                <li className='border-2 cursor-pointer hover:bg-[#8EE3FD] hover:text-black'>
                <Link to={`/`}>Embeddings</Link>
                </li>
            </ul>
        </div>
    </Link>
    {/*EBF1FF  24386C*/}
  </div>
  <div className='flex justify-between'>
    <Link to={`https://github.com/Chim1995/orthogonaldb`} className='flex items-center justify-between m-5'>
        <FaGithubSquare></FaGithubSquare>
        Github
    </Link>
    <Link to={`https://github.com/Chim1995/orthogonaldb`} className='flex items-center justify-between m-5'>
        <FaCloud></FaCloud>
        Cloud
    </Link>
  </div>
</div>
}
