import React from 'react'
import { NavBar } from '../Components/navBar'

export const Home = () => {
  return (
    <div className="w-[100%] h-[100%] bg-gradient-to-br from-[#93EDFD] to-[#7DB1FB flex flex-col">
    {/*Navbar */}
    <NavBar></NavBar>
    {/* Main body*/}
    <div className="h-[80%] w-[100%] flex items-center">
      <div className='w-[50%] flex items-center justify-center'>
        <img className='h- w-40' src='dblogo.png'></img>
      </div>
      <div className='w-[50%]'>
        <p className='text-2xl font-extrabold m-3'>
            Custosys!!!!
        </p>
        <p className='m-3'>
        Powerful and reliable <br></br>Security for your cloud native workloads
        </p>
        <div className='bg-[#EBF1FF] w-[50%] m-3 p-2'>
            <p className='text-[#24386C]'>docker pull orthogonaldb/custosys</p>
            <p className='text-[#24386C]'>docker run -p 7334:7334 orthogonaldb/orthogonal</p>
        </div>
      </div>
    </div>

    {/* Footer*/}
    <div className="h-[12%] w-[100%] text-white">Footer</div>
  </div>
  )
}
